<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = `Nº${item.idsync_upload} - ${this.$options.filters.shortDateTime(item.fecha)}`
      item.subtitle = `${item.cant_cambios} ${this.$options.filters.pluralize('cambio', item.cant_cambios, 's')}`
      if (item.con_errores) {
        item.avatar = 'ERR'
        item.avatarColor = 'error'
      } else {
        item.avatar = 'OK'
        item.avatarColor = 'success'
      }
      return item
    }
  }
}
</script>
